import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="結婚指輪のカスタムオーダーCAD-platinum会社概要"
        description="10,000点を超えるデザインからWEBで簡単にカスタムオーダーできるCAD-platinumは株式会社ミスプラチナが運営。所在地：東京都渋谷区神宮前3-16-16-1F　代表取締役：山本豊"
        page="company"
    />
)

const CompanyPage: React.FC = () => {
    return (
        <Layout breadcrumbs={[{ name: '会社概要' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <Title>
                        <Txt>会社概要</Txt>
                    </Title>
                    <StaticImage src="../images/company/01.jpg" alt="" />
                    <Table>
                        <Row>
                            <H>
                                <HTxt size="s">会社名</HTxt>
                            </H>
                            <D>
                                <Txt size="s">株式会社ミスプラチナ</Txt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <HTxt size="s">事業内容</HTxt>
                            </H>
                            <D>
                                <Txt size="s">
                                    ジュエリー販売 / ブライダル広告
                                </Txt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <HTxt size="s">所在地</HTxt>
                            </H>
                            <D>
                                <Txt size="s">東京都渋谷区神宮前3−16−16−1F</Txt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <HTxt size="s">代表取締役</HTxt>
                            </H>
                            <D>
                                <Txt size="s">山本豊</Txt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <HTxt size="s">電話番号</HTxt>
                            </H>
                            <D>
                                <Txt size="s">03-6419-7057</Txt>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <HTxt size="s">姉妹サイト</HTxt>
                            </H>
                            <D>
                                <a
                                    href="https://padia-japan.com"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Txt size="s">Padia-JAPAN</Txt>
                                </a>
                            </D>
                        </Row>
                        <Row>
                            <H>
                                <HTxt size="s">メールアドレス</HTxt>
                            </H>
                            <D>
                                <Txt size="s">info@missplatinum.net</Txt>
                            </D>
                        </Row>
                    </Table>
                    <StaticImage src="../images/company/02.jpg" alt="" />
                </Grid>
            </Grid>
        </Layout>
    )
}

const Title = styled('h1')({
    display: 'flex',
    margin: 0,
    padding: '12px',
})
const Table = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '340px',
    margin: '20px auto',
    border: '1px solid #aaaaaa',
    borderBottom: 'none',
})
const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #aaaaaa',
})
const H = styled('div')({
    width: '140px',
    padding: '8px',
    borderRight: '2px solid #aaaaaa',
    backgroundColor: '#f5f5f5',
})
const HTxt = styled(Txt)({
    fontWeight: 'bold',
})
const D = styled('div')({
    flex: 1,
    padding: '8px',
})

export default CompanyPage
